import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { Label } from 'react-konva';
import Editor from 'react-simple-wysiwyg';
import axios from 'axios';
import { frontEnd_API, header } from '../../../Config/Config';
import { toast, ToastContainer } from 'react-toastify';
import ProductPreviewImage from './ProductPreviewImageComponent';
import Loader from '../../../Components/Loader';
import Swal from 'sweetalert2';
import { Input } from 'reactstrap';
import { keyCode } from '../../../Config/Constant';

function ProductUpdate() {
    const location = useLocation();
    const navigate = useNavigate();
    
    const product = {...location?.state?.data}
    console.log("product 19 :: ", product)
    const [brandingStatus, setBrandingStatus] = useState(product?.isBranding == 1)
    const [priceCountBoxStatus, setPriceCountBoxStatus] = useState(false)
    const [selectedVariantData, setSelectedVariantData] = useState([])
    const [colorVariantData, setColorVariantData] = useState([])
    const [currentMockupObj, setCurrentMockupObj] = useState({})
    const [primaryPreview, setPrimaryPreview] = useState(0)
    
    // state section ------------
    const [productFormSubmitStatus, setProductFormSubmitStatus] = useState(false)
    const [productForm, setProductForm] = useState({})
    // ------------

    // const roundToTwoDecimalPlaces = (num = 0) => Math.round(num * 100) / 100
    const roundToTwoDecimalPlaces = (num = 0) => Math.round(num)

    // function for manage product form data ------------
    const manageProductFormData = async () => {

        const productData = product
        
        if (product?.variantData)
            setSelectedVariantData([...(product?.variantData?.map(e => e?.name))])
        
        const previewObject = productData?.previewImagesData?.length > 0 ? productData?.previewImagesData[0] : '';
        
        const colorData = productData?.variantData?.find(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.name?.toLowerCase()))?.data?.map(x => ({
            label: x?.label,
            code: x?.code,
            ...previewObject
        }))
        if (colorData?.length > 0)
            setColorVariantData(colorData)
        
        productData.defaultColor = productData.defaultColor?? (colorData?.length > 0 ? colorData[0]?.code : '')

        setProductForm(
            {
                masterProductId: productData?.masterProductId,
                providerId: productData?.providerId,
                providerBusinessId: productData?.providerBusinessId,
                providerProductId: productData?.providerProductId,
                defaultColor: productData?.defaultColor,
                productName: productData?.name,
                description: productData?.description,
                providerPrice: productData?.providerPrice,
                providerDesignPrice: productData?.providerDesignPrice,
                providerPayble: productData?.providerPayble,
                platformChargePercentage: productData.platformChargePercentage,
                platformChargeAmount: productData.platformChargeAmount,
                taxPercentage: productData.taxPercentage,
                taxAmount: productData.taxAmount,
                shippingCharge: productData.shippingCharge,
                rtoCharge: productData.rtoCharge,
                payblePrice: productData.payblePrice,
                profitInPercentage: productData.profitInPercentage,
                previousProfitInPercentage: productData.profitInPercentage,
                profitInAmount: productData.profitInAmount,
                previousProfitInAmount: productData.profitInAmount,
                price: productData.price,
                mrp: productData.mrp?? productData?.price,
                height: productData.height,
                width: productData.width,
                length: productData.length,
                weight: productData.weight,
                previousMrp: productData.mrp,
                profitPercentage: productData.profitPercentage,
                isBranding: productData?.isBranding,
                designData: [],
                cartesianData: productData.cartesianData ?? [],
                variantData: productData?.variantData ?? [],
                previewImagesData: productData?.previewImagesData ?? []
            }
        )

        const tempIndex = location
    }
    // ------------

    // function for manage product price ------------
    const manageProductPrice = async (obj = { from: 'amt', val: 0 }) => {
        if (['amt','per'].includes(obj?.from)) {
            productForm.profitInPercentage = productForm.profitInAmount = 0
            productForm.price = roundToTwoDecimalPlaces(productForm.payblePrice)
            console.log('productForm.payblePrice :: ', productForm?.payblePrice)
            if (obj.from === 'amt') {
                productForm.profitInPercentage = roundToTwoDecimalPlaces(Number(obj.val * 100 / parseFloat(productForm.payblePrice)))
                productForm.profitInAmount = obj.val
                productForm.previousProfitInPercentage = productForm.profitInPercentage
            }
            if (obj.from === 'per') {
                productForm.profitInPercentage = obj.val
                productForm.profitInAmount = roundToTwoDecimalPlaces(parseFloat(productForm?.payblePrice) * obj.val / 100)
                productForm.previousProfitInAmount = productForm.profitInAmount
            }
            productForm.price = parseFloat(productForm.payblePrice) + parseFloat(productForm.profitInAmount)
            productForm.mrp = productForm.price
        }
        if (obj.from == 'mrp')
            productForm.mrp = obj.val

        setProductForm({ ...productForm })
    }
    // ------------

    // function for save product detail ------------
    const productFormSubmit = async () => {

        productForm.previewImagesData = productForm?.previewImagesData?.map((row, index) => ({
                ...row,
                isPrimary: (index == primaryPreview) ? 1 : 0
            }))

        
        try {

            delete productForm?.previousProfitInPercentage
            delete productForm?.previousProfitInAmount
            delete productForm?.previousMrp

            productForm.isBranding = brandingStatus ? 1 : 0

            console.log('productForm  :: ', productForm)
            // return
            const updateId = location?.state?.data?.productId
            const { data, status } = await axios.put(`${frontEnd_API?.storeProduct}/information/${(updateId)}`, productForm, header);
            
            if (status == 200) {
                setProductFormSubmitStatus(false)
                toast(data?.message)
                navigate('/products')
            }
        } catch (error) {
            setProductFormSubmitStatus(false)
            toast(error?.response?.data?.message)
        }
    }
    // ------------

    // function for update all variant price ------------
    const updateVariantPrice = (obj = {}) => {

        const data = productForm?.cartesianData?.map((row, index) => {

            if (['per', 'amt'].includes(obj.from)) {
                if (obj.from == 'per') {
                    row.profitPercentage = parseFloat(productForm.profitInPercentage)
                    row.profitAmount = roundToTwoDecimalPlaces(Number(row?.payblePrice) * row.profitPercentage / 100)
                }
    
                if (obj.from == 'amt') {
                    row.profitAmount = parseFloat(productForm.profitInAmount)
                    row.profitPercentage = roundToTwoDecimalPlaces(Number(row.profitAmount * 100 / row.payblePrice))
                }
    
                row.price = roundToTwoDecimalPlaces(parseFloat(row.payblePrice) + parseFloat(row.profitAmount))
                row.mrp = roundToTwoDecimalPlaces(row.price)
            }
            if (obj.from == 'mrp')
                row.mrp = roundToTwoDecimalPlaces(productForm.mrp)

            console.log(`210 row:: ${index} : ${row.payblePrice}, ${row.profitAmount}, ${row?.price}`)

            return row
        })

        productForm.cartesianData = data
        setProductForm({ ...productForm })
    }
    // ------------

    // function for manage variant price ------------
    const manageVariantPrice = (obj = { from: 'amt' }) => {

        if (obj.from) {
            const row = productForm?.cartesianData[obj?.index]

            if (['amt', 'per'].includes(obj.from)) {
                if (obj.from == 'per') {
                    row.profitPercentage = obj.value
                    row.profitAmount = roundToTwoDecimalPlaces(parseFloat(row?.payblePrice) * parseFloat(row.profitPercentage) / 100)
                }
    
                if (obj.from == 'amt') {
                    row.profitAmount = obj.value
                    row.profitPercentage = roundToTwoDecimalPlaces(parseFloat(row.profitAmount * 100 / parseFloat(row.payblePrice)))
                }
                row.price = roundToTwoDecimalPlaces(parseFloat(row.payblePrice) + parseFloat(row.profitAmount))
                row.mrp = roundToTwoDecimalPlaces(row.price)
            }
            if (obj.from == 'mrp')
                row.mrp = roundToTwoDecimalPlaces(obj.value)

            console.log(`210 row:: ${obj?.index} : ${row} `)
            productForm.cartesianData[obj.index] = row

            setProductForm({ ...productForm })
        }
    }
    // ------------

    useEffect(() => { manageProductFormData() }, [])
    
    return (
        <div className='custom-product-create position-relative'>

            {/* Loader For Product Create */}
            {
                productFormSubmitStatus &&
                <div className='position-absolute w-100 h-100'>
                    <div
                        className='position-sticky top-0 start-0 w-100 flex-center-align'
                        style={{
                            backgroundColor: 'rgba(255,255,255,1)',
                            zIndex: "9999",
                            height: "100vh"
                        }}
                    >
                        <div className='d-grid text-center text-success gap-1'>
                            <div className='w-100 aspect-2-1 aspect-md-2-1'>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Container className='py-4'>
                <Row>
                    <Col xl='12'>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Preview & Mockup</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='5' md='6' xs='12'>
                                        <div
                                            className='preview-image border border-2 border-dashed'
                                        >
                                            <ProductPreviewImage
                                                colorCode={productForm?.defaultColor?? '#ffffff'}
                                                objectData={{ preview:productForm?.previewImagesData?.length > 0 ? productForm?.previewImagesData[primaryPreview]?.preview : null }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='7' md='6' xs='12'>
                                        <Row className='gy-2'>
                                            <Col xl='12'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Color Images</span>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2 gap-sm-3 mb-3'>
                                                {
                                                    colorVariantData?.map((row, index) => <>
                                                        <div
                                                            key={index}
                                                            className={`pointer`}
                                                            onClick={() => {
                                                                productForm.defaultColor = row?.code?? ''
                                                                setPrimaryPreview(0)
                                                                setProductForm({...productForm})
                                                            }}
                                                        >
                                                            <div className=' sm-preview-img position-relative'>
                                                                <img
                                                                    src={row?.preview}
                                                                    style={{ objectFit: "cover", backgroundColor: `${row?.code ?? '#ffffff'}` }}
                                                                    alt={productForm?.productName + ' ' + row?.label}
                                                                    className={`w-100 h-100 rounded-1 border ${row?.code == productForm?.defaultColor ? 'border-2 border-dark' : ''}`}
                                                                />
                                                                { productForm?.defaultColor == row?.code && (
                                                                    <span className='position-absolute top-0 end-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                )}
                                                            </div>
                                                            <div
                                                                className='bg-transparent fs-12 fs-sm-10 fw-semibold text-center h-25 pt-1 text-capitalize'
                                                                style={{ color: '#808c98' }}
                                                            >
                                                                {row?.label}
                                                            </div>
                                                        </div>
                                                    </>)
                                                }
                                                </div>
                                            </Col>
                                            <Col xl='12'>
                                                <div className='fs-20 fs-md-18 fs-sm-16 fw-semibold mb-2 mt-2 mt-md-0'>
                                                    <span>Mockup Images</span>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2 gap-sm-3 mb-3'>
                                                    {
                                                        productForm?.previewImagesData?.map((row, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={`position-relative sm-preview-img pointer rounded-1 border mt-2 ${index == primaryPreview ? 'border-2 border-dark' : ''}`}
                                                                >
                                                                    <ProductPreviewImage
                                                                        isList={true}
                                                                        colorCode={productForm?.defaultColor?? '#ffffff'}
                                                                        objectData={{ preview: row?.preview }}
                                                                        // selectionData={index}
                                                                        onPrevSelect={() => setPrimaryPreview(index)}
                                                                    />
                                                                    { primaryPreview == index && (
                                                                    <span className='position-absolute top-0 end-0 fs-10 badge bg-success border-0 rounded-0'>Default</span>
                                                                )}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Product Detail</h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl='12'>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>title</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                placeholder='product title'
                                                value={productForm?.productName}
                                                required
                                                onChange={(e) => {
                                                    productForm.productName = e?.target?.value
                                                    setProductForm({ ...productForm })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xl='12'>
                                        <div className='form-group'>
                                            <Label className='form-label'>description</Label>
                                            <Editor
                                                value={productForm?.description}
                                                onChange={(e) => {
                                                    productForm.description = e?.target?.value
                                                    setProductForm({ ...productForm })
                                                }}
                                                style={{ height: '250px' }}
                                            />
                                        </div>
                                    </Col>
                                    {
                                        (product?.brandingStatus && 1 < 0) && (
                                            <Col xl='12'>
                                                <div className='d-flex gap-1 flex-column my-3'>
                                                    <span className='fw-semibold'>Do you want to enable custom branding services for this product?</span>
                                                    <div>
                                                        <Input
                                                            id='branding'
                                                            type='checkbox'
                                                            checked={brandingStatus}
                                                            onChange={(e) => setBrandingStatus(!brandingStatus)}
                                                        />&nbsp;
                                                        <Label for="branding" className="mb-0 pointer">Yes</Label>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardHeader className='bg-transparent py-3'>
                                <h4 className='mb-0 fw-bolder'>Price & Variant</h4>
                            </CardHeader>
                            <CardBody>                                
                                <Row xl={5} md={3} xs={1}>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex justify-content-between align-items-center'>
                                                <span>Payable amount</span>
                                                <div className='position-relative'>
                                                    <span onClick={() => setPriceCountBoxStatus(!priceCountBoxStatus)} className="bi bi-info-circle-fill mr-1"></span>
                                                    {
                                                        (priceCountBoxStatus) && (
                                                            <div className='position-absolute border rounded-2 p-2 bg-white bottom-100 end-50 fs-12' style={{width:'250px!important;'}}>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Provider price</span>
                                                                    <span className='fw-bold'>{productForm?.providerPrice}</span>
                                                                </div>
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Design charges</span>
                                                                    <span className='fw-bold'>{productForm?.providerDesignPrice}</span>
                                                                </div>
                                                                {/* {
                                                                    productForm?.platformChargeAmount > 0 && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Platform fee</span>
                                                                            <span className='fw-bold'>{productForm?.platformChargeAmount}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    productForm?.taxPercentage > 0 && (
                                                                        <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                            <span className='text-nowrap'>Tax ({productForm?.taxPercentage}%)</span>
                                                                            <span className='fw-bold'>{productForm?.taxAmount}</span>
                                                                        </div>
                                                                    )
                                                                } */}
                                                                <div className='d-flex gap-3 justify-content-between ml-2'>
                                                                    <span className='text-nowrap'>Shipping as per actual</span>
                                                                    {/* <span className='fw-bold'>{productForm?.shippingCharge}</span> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.payblePrice}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Profit (%)</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.profitInPercentage}
                                                onChange={(e) => manageProductPrice({ from: 'per', val: Number(e?.target?.value ?? 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.profitInPercentage != productForm.previousProfitInPercentage) {
                                                        Swal.fire({
                                                            title: `Do you want to apply this change to all the variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#12715B',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousProfitInPercentage = productForm.profitInPercentage
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'per' })
                                                                return
                                                            }
                                                            manageProductPrice({ from: 'per', val: Number(productForm?.previousProfitInPercentage?? 0) })
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Profit ({keyCode?.inr})</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.profitInAmount}
                                                onChange={(e) => manageProductPrice({ from: 'amt', val: Number(e?.target?.value ?? 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.profitInAmount != productForm.previousProfitInAmount) {
                                                        Swal.fire({
                                                            title: `Do you want to apply this change to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#12715B',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousProfitInAmount = productForm.profitInAmount
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'amt' })
                                                                return
                                                            }
                                                            manageProductPrice({ from: 'amt', val: Number(productForm?.previousProfitInAmount?? 0) })
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label d-flex align-items-end'>
                                                Selling Price
                                                {/* Selling Price{productForm?.taxPercentage > 0 && (<div className='text-dark fs-12 fw-semibold'>&nbsp;({productForm?.taxPercentage}% incl.)</div>)} */}
                                            </Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.price}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group mb-3'>
                                            <Label className='form-label'>Set MRP</Label>
                                            <input
                                                className='form-control rounded-0 fs-6 py-2'
                                                value={productForm?.mrp}
                                                onChange={(e) => manageProductPrice({ from: 'mrp', val: Number(e?.target?.value ?? 0) })}
                                                onBlur={(e) => {
                                                    if (productForm.mrp != productForm.previousMrp) {

                                                        Swal.fire({
                                                            title: `Do you want to apply this MRP to all variant??`,
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#12715B',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Confirm',
                                                            cancelButtonText: 'No',
                                                        }).then(async (result) => {
                                                            if (result.isConfirmed) {
                                                                productForm.previousMrp = productForm.mrp
                                                                setProductForm({ ...productForm })
                                                                updateVariantPrice({ from: 'mrp' })
                                                                return
                                                            }
                                                            manageProductPrice({ from: 'mrp', val: Number(productForm?.previousMrp?? 0) })
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        productForm?.cartesianData && (
                                            <Col xl='12' className='cartesian'>
                                                <hr />
                                                <div className='table-responsive mb-4'>
                                                    <Table className='table table-centered table-hover align-middle table-nowrap mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={Object?.keys(productForm?.cartesianData)?.length + 1}>
                                                                    <h5 className='mb-0'>Product Variant Cartesian</h5>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                {selectedVariantData?.map((row) => <th className='fs-sm-14 text-capitalize'>{row}</th>)}
                                                                <th className='fs-sm-14'>Production cost</th>
                                                                <th className='fs-sm-14'>Profit (%)</th>
                                                                <th className='fs-sm-14'>Profit ({keyCode?.inr})</th>
                                                                <th className='fs-sm-14'>Sale price</th>
                                                                <th className='fs-sm-14'>MRP</th>
                                                                {/* <th>Stock</th> */}
                                                                <th className='fs-sm-14'>Available</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                productForm?.cartesianData?.map((row, index) => <>
                                                                    <tr className={row?.isPrimary ? 'disabled' : ''}>
                                                                        {row?.variant?.map((val) => <td className='fs-sm-14'>{val?.label}</td>)}
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.payblePrice}
                                                                                style={{ width: '100px' }}
                                                                                disabled
                                                                            />
                                                                        </th>
                                                                        {
                                                                            row?.isPrimary ? (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitPercentage}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                    <th>
                                                                                        <input
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            disabled
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <th>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            value={row?.profitPercentage}
                                                                                            style={{ width: '100px' }}
                                                                                            onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'per' })}
                                                                                        />
                                                                                    </th>
                                                                                    <th>
                                                                                        <input
                                                                                            type='number'
                                                                                            className='form-control rounded-0 fs-sm-14'
                                                                                            min='0'
                                                                                            value={row?.profitAmount}
                                                                                            style={{ width: '100px' }}
                                                                                            onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'amt' })}
                                                                                        />
                                                                                    </th>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.price}
                                                                                style={{ width: '100px' }}
                                                                                disabled
                                                                            />
                                                                        </th>
                                                                        <th>
                                                                            <input
                                                                                className='form-control rounded-0 fs-sm-14'
                                                                                value={row?.mrp}
                                                                                style={{ width: '100px' }}
                                                                                onChange={(e) => manageVariantPrice({ index, value: e?.target?.value > 0 ? Number(e?.target?.value) : 0, from: 'mrp' })}
                                                                            />
                                                                        </th>
                                                                        <td>
                                                                            <label className="switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={row?.isAvailable}
                                                                                    value={row?.isAvailable}
                                                                                    onChange={(e) => {
                                                                                        productForm.cartesianData[index].isAvailable = !row?.isAvailable
                                                                                        setProductForm({ ...productForm })
                                                                                    }}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </>)
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='rounded-0 mb-4'>
                            <CardBody>
                                <div className='d-flex gap-2 align-items-center justify-conten-end'>
                                    <Button
                                        className='text-capitalize btn btn-success rounded-0 py-2 px-3 fs-6'
                                        color='success'
                                        onClick={() => {
                                            setProductFormSubmitStatus(true)
                                            productFormSubmit()
                                        }}
                                        disabled={productFormSubmitStatus}
                                        style={{ minWidth: "139px" }}
                                    >
                                        { productFormSubmitStatus ? <Spinner animation="border" size="sm" /> : "Save product" }
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default ProductUpdate